<template>
  <div class="wechat-qr" v-if="visible">
    <div class="qr-container">
      <div class="qr">
        <div class="qr-head">
          在线客服
          <!-- <div class="cross" @click="cancel"></div> -->
        </div>
        <div class="qr-body">
          <div class="wechat">
            <img src="../assets/official.png" />
            <div style="font-size:0.24rem;color:#424242;">
              微信扫码(长按保存识别)
            </div>
            <div style="font-size:0.28rem;color:#3A3A3A;margin-top: 0.12rem;">
              客服在线时间：10:00 - 22:00
            </div>
          </div>
        </div>
        <div class="qr-footer" @click="cancel">
          <div class="cancel">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Wechat-QR",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel")
    },
  },
}
</script>

<style lang="less" scoped>
.wechat-qr {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.45);
  .qr-container {
    z-index: 99;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .close-area {
      flex: 1;
    }
    .qr {
      width: 6.44rem;
      height: 6.72rem;
      background: white;
      border-radius: .16rem;
      box-shadow: 0px 0.06rem 0.16rem 0px rgba(0, 0, 0, 0.08),
        0px 0.09rem 0.04rem 0px rgba(0, 0, 0, 0.05),
        0px 0.12rem 0.48rem 0.16rem rgba(0, 0, 0, 0.03);
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.28rem;
      .qr-head {
        width: 100%;
        height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2C2C2C;
        font-size: 0.36rem;
      }
      .qr-body {
        flex: 1;
        display: flex;
        justify-content: space-evenly;
        .wechat {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 0.14rem;
          img {
            height: 3.24rem;
            width: 3.24rem;
          }
        }
      }
      .qr-footer {
        border-top: 0.01rem solid rgba(0, 0, 0, 0.09);
        height: 1.1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .cancel {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.36rem;
          color: #69D9B6;
          cursor: pointer;
        }
      }
    }
    .close-area {
      flex: 1;
    }
  }
}
</style>
