<template>
  <div class="pay-method">
    <div class="title">支付方式</div>
    <div class="methods">
      <div class="method" @click="methodTab('wechatPay')">
        <div class="left-part">
          <svg
            t="1679916127630"
            class="icon"
            viewBox="0 0 1228 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4984"
            width="0.38rem"
            height="0.32rem"
          >
            <path
              d="M530.8928 703.1296a41.472 41.472 0 0 1-35.7376-19.8144l-2.7136-5.5808L278.272 394.752a18.7392 18.7392 0 0 1-2.048-8.1408 19.968 19.968 0 0 1 20.48-19.3536c4.608 0 8.8576 1.4336 12.288 3.84l234.3936 139.9296a64.4096 64.4096 0 0 0 54.528 5.9392L1116.2624 204.8C1004.9536 80.896 821.76 0 614.4 0 275.0464 0 0 216.576 0 483.6352c0 145.7152 82.7392 276.8896 212.2752 365.5168a38.1952 38.1952 0 0 1 17.2032 31.488 44.4928 44.4928 0 0 1-2.1504 12.3904l-27.6992 97.4848c-1.3312 4.608-3.328 9.3696-3.328 14.1312 0 10.752 9.216 19.3536 20.48 19.3536 4.4032 0 8.0384-1.536 11.776-3.584l134.5536-73.3184c10.1376-5.5296 20.7872-8.96 32.6144-8.96 6.2976 0 12.288 0.9216 18.0736 2.5088 62.72 17.0496 130.4576 26.5728 200.5504 26.5728C953.7024 967.168 1228.8 750.592 1228.8 483.6352c0-80.9472-25.4464-157.1328-70.0416-224.1024l-604.9792 436.992-4.4544 2.4064a42.1376 42.1376 0 0 1-18.432 4.1984z"
              fill="#15BA11"
              p-id="4985"
            ></path>
          </svg>
          <div class="name">微信支付</div>
        </div>
        <div class="right-part">
          <svg
            v-if="method == 'wechatPay'"
            t="1679918602414"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2759"
            width="0.40rem"
            height="0.40rem"
          >
            <path
              d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m-74.965333 550.4L346.453333 545.152a42.666667 42.666667 0 1 0-60.330666 60.330667l120.704 120.704a42.666667 42.666667 0 0 0 60.330666 0l301.653334-301.696a42.666667 42.666667 0 1 0-60.288-60.330667l-271.530667 271.488z"
              fill="#52C41A"
              p-id="2760"
            ></path>
          </svg>
          <div v-else class="check"></div>
        </div>
      </div>
      <div class="method" @click="methodTab('alipay')">
        <div class="left-part">
          <svg
            t="1679915984273"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3068"
            width="0.32rem"
            height="0.32rem"
          >
            <path
              d="M1024.0512 701.0304V196.864A196.9664 196.9664 0 0 0 827.136 0H196.864A196.9664 196.9664 0 0 0 0 196.864v630.272A196.9152 196.9152 0 0 0 196.864 1024h630.272a197.12 197.12 0 0 0 193.8432-162.0992c-52.224-22.6304-278.528-120.32-396.4416-176.64-89.7024 108.6976-183.7056 173.9264-325.3248 173.9264s-236.1856-87.2448-224.8192-194.048c7.4752-70.0416 55.552-184.576 264.2944-164.9664 110.08 10.3424 160.4096 30.8736 250.1632 60.5184 23.1936-42.5984 42.496-89.4464 57.1392-139.264H248.064v-39.424h196.9152V311.1424H204.8V267.776h240.128V165.632s2.1504-15.9744 19.8144-15.9744h98.4576V267.776h256v43.4176h-256V381.952h208.8448a805.9904 805.9904 0 0 1-84.8384 212.6848c60.672 22.016 336.7936 106.3936 336.7936 106.3936zM283.5456 791.6032c-149.6576 0-173.312-94.464-165.376-133.9392 7.8336-39.3216 51.2-90.624 134.4-90.624 95.5904 0 181.248 24.4736 284.0576 74.5472-72.192 94.0032-160.9216 150.016-253.0816 150.016z"
              fill="#009FE8"
              p-id="3069"
            ></path>
          </svg>
          <div class="name">
            支付宝
          </div>
        </div>
        <div class="right-part">
          <svg
            v-if="method == 'alipay'"
            t="1679918602414"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2759"
            width="0.40rem"
            height="0.40rem"
          >
            <path
              d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m-74.965333 550.4L346.453333 545.152a42.666667 42.666667 0 1 0-60.330666 60.330667l120.704 120.704a42.666667 42.666667 0 0 0 60.330666 0l301.653334-301.696a42.666667 42.666667 0 1 0-60.288-60.330667l-271.530667 271.488z"
              fill="#52C41A"
              p-id="2760"
            ></path>
          </svg>
          <div v-else class="check"></div>
        </div>
      </div>
    </div>

    <div class="browser-open-guide" v-if="showBrowserGuide">
      <div class="containner">
        <div class="payment">
          <img src="../assets/browser.png" />
          <div class="btn-cs" @click="payFinish">支付完成</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showBrowserGuide: {
      type: Boolean,
      default: false
    },
    method: {
      type: String,
      default: 'wechatPay'
    }
  },
  methods: {
    methodTab (method) {
      this.$emit('methodTab', method)
    },
    payFinish () {
      this.$emit('getOrderInfo', true)
    }
  }
}
</script>
<style scoped lang="less">
.pay-method {
  width: 7.02rem;
  display: flex;
  padding: 0.12rem 0.24rem;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  margin-top: 0.12rem;
  .title {
    font-size: 0.28rem;
    display: flex;
    width: 100%;
    height: 0.9rem;
    align-items: center;
  }
  .methods {
    display: flex;
    flex-direction: column;
    width: 100%;
    .method {
      cursor: pointer;
      display: flex;
      height: 0.9rem;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .left-part {
        display: flex;
        height: 0.9rem;
        align-items: center;
        .name {
          margin-left: 0.12rem;
          font-size: 0.28rem;
        }
      }
      .right-part {
        display: flex;
        align-items: center;
        .check {
          width: 0.28rem;
          height: 0.28rem;
          border-radius: 50%;
          border: 0.03rem solid #969799;
        }
      }
    }
  }
  .browser-open-guide {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100000;
    background: rgba(0, 0, 0, 0.57);
    .containner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      .payment {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 7.5rem;
        height: 100%;
        img {
          width: 5.58rem;
          height: 3.4rem;
          margin-left: 2rem;
        }
        .btn-cs {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.2rem;
          height: 0.8rem;
          background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
          border-radius: 0.4rem;
          border: 0.02rem solid #53cda9;
          color: #ffffff;
          font-size: 0.32rem;
          margin-top: 3rem;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
